<template>
  <div>
    <CRow>
      <CCol md="12">
        <CRow>
          <CFormLabel for="activeProduct" class="col-sm-3 col-form-label">
            Producto:
          </CFormLabel>
          <div class="col-sm-9">
            <select class="form-control" @change="selectActiveProduct($event)">
              <option v-for="item in items" :key="item.code" :value="item.code">
                {{ item.nombre }}
              </option>
            </select>
          </div>
        </CRow>
        <CSpinner v-if="loading" variant="grow" size="lg"/>
        <CRow v-if="activeProduct" class="mt-3">
          <CCol md="3">
            <img class="imageProduct" :src="activeProduct.variations[0].UserText" alt="" width="100%">
          </CCol>
          <CCol md="8">
            <table class="table">
              <tr>
                <th>Licencia para</th>
                <td>
                  <select class="form-control" v-model="activeProduct.equipos" @change="updateVigencia(activeProduct)">
                    <option v-for="variation in activeProduct.variations">
                      {{ variation.U_SBS_MAQUINAS }}
                    </option>
                  </select>
                </td>
                <td>dispositivos</td>
              </tr>
              <tr>
                <th>Vigencia de la licencia</th>
                <td>
                  <select class="form-control">
                    <option v-for="vigencia_option in activeProduct.vigencia_options">
                      {{ vigencia_option }}
                    </option>
                  </select>
                </td>
                <td>años</td>
              </tr>
              <tr>
                <th>
                  Cantidad
                </th>
                <td>
                  <div class="qtyContainer">
                  <CButton class="lessButton" color="info" @click="subQuantity(activeProduct.selected)">-</CButton>
                    <CInput
                      class="qtyInput"
                      required
                      v-model="activeProduct.selected.quantity"
                      readonly
                    />
                    <CButton class="addButton" color="info" @click="addQuantity()">+</CButton>
                  </div>
                </td>
                <td></td>
              </tr>
              <tr>
                <th>
                  Precio unitario
                </th>
                <td>
                  $ {{ formatPrice(activeProduct.selected.Price) }}
                </td>
                <td>
                  * precio no incluye impuestos
                </td>
              </tr>
              <tr v-if="typeof activeProduct.selected.min === 'object'">
                <th>
                  Mejora tu precio comprando
                </th>
                <td>
                  {{ activeProduct.selected.min.min }}
                </td>
              </tr>
              <tr>
                <th>Valor total</th>
                <td>
                  $ {{ formatPrice(activeProduct.selected.Price * activeProduct.selected.quantity) }}
                </td>
                <td>
                  <CButton color="primary" @click="continueRouter()">
                    <font-awesome-icon icon="cart-plus" /> Comprar
                  </CButton>
                </td>
              </tr>
            </table>
          </CCol>
        </CRow>
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="6" md="4" v-for="product in noEset" :key="product.ItemCode">
        <CCard>
          <img class="imageProduct" :src="product.UserText" alt="" width="100%">
          <CCardBody>
            <CCardTitle><center><strong>{{ product.ItemName }}</strong></center></CCardTitle>
            <CCardText>
              <span>
                Compra minima: {{ configs.filter(item => item.ItemCode === product.ItemCode).length > 0 ? 
                  configs.filter(item => item.ItemCode === product.ItemCode)[0].min
                  : 1 }}
              </span>
            </CCardText>
            <CCardTitle><center> ${{ formatPrice(product.Price) }} </center></CCardTitle>
            <CCardText><span v-html="product.description"></span></CCardText>
          </CCardBody>
          <CCardFooter>
              <center>
                <div class="qtyContainer">
                  <CButton class="lessButton" color="info" @click="changeProductQuantity(product, 'minus')">-</CButton>
                  <CInput
                    class="qtyInput"
                    v-model="product.quantity"
                    required
                    @keypress="isNumber($event)"
                    @change="changeProductQuantity(product, 'change')"
                  />
                  <CButton class="addButton" color="info" @click="changeProductQuantity(product, 'plus')">+</CButton>
                </div>
              <CButton class="cartAdd" color="info" @click="(event) => $store.commit('add', product)"><font-awesome-icon icon="cart-plus" /></CButton>
            </center>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Resource from '@/api/resource'
const resourceItems = new Resource("items");
const resourceConfig = new Resource("config/products");

export default {
  name: 'Product',
  data: function () {
    return {
      loading: false,
      form: {},
      products: [],
      items: [],
      activeProduct: null,
      noEset: [],
      configs: [],
      lessons: [],
      show: true,
      isCollapsed: true,
      loremIpsum: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.'
    }
  },
  async mounted() {
    await this.loadConfig();
    if(this.$store.state.user.role == "Subdistribuidor"){
      this.$router.push('/stock').catch(() => {});
    }else{
      await this.loadItems();
    }
  },
  methods: {
    async loadItems(){
      this.loading = true;
      await resourceItems.list().then(response => {
        var clean = response.data.filter(
          (arr, index, self) =>
          index === self.findIndex((t) => (t.ItemCode === arr.ItemCode))
        ).filter(function(item){
          return item.ItemCode.includes('DST');
        });

        for (let i = 0; i < clean.length; i++) {
          clean[i].quantity = 1;

          // Producto que no pertenece a la oferta original (cursos y recargas)
          if (clean[i].ItemCode.includes('CUR') || clean[i].ItemCode.includes('REC')) {
            this.noEset.push(clean[i]);
            continue;
          }

          var min = this.configs.filter((item) => item.ItemCode === clean[i].ItemCode).length > 0 ?
                    this.configs.filter((item) => item.ItemCode === clean[i].ItemCode)[0].min : 1;

          // El producto es un descuento, los cargamos luego
          if (min > 1) {
            continue;
          }

          // Buscamos si el producto padre ya fue cargado
          var product = this.items.find((item) => item.code == clean[i].U_SBS_PRODUCTO);

          if (!product) {
            this.items.push({
              code: clean[i].U_SBS_PRODUCTO,
              variations: [],
              equipos: clean[i].U_SBS_MAQUINAS,
              vigencia: clean[i].U_SBS_VIGENCIA,
              vigencia_options: [],
              nombre: clean[i].ItemName
                        .replace('1PC', '')
                        .replace('1 AÑO', '')
                        .replace('2PC', '')
                        .replace('1AÑO', '')
                        .replace('DISTRI', '')
            });
            product = this.items.find((item) => item.code == clean[i].U_SBS_PRODUCTO);
          }

          // Agregamos la variacion al producto padre
          product.variations.push(clean[i]);
        }

        // Ahora buscamos los descuentos
        for (let i = 0; i < clean.length; i++) {
          var min = this.configs.filter((item) => item.ItemCode === clean[i].ItemCode).length > 0 ?
                    this.configs.filter((item) => item.ItemCode === clean[i].ItemCode)[0].min : 1;

          if (min <= 1) {
            continue;
          }

          clean[i].min = min;
          
          var product = this.items.find((item) => item.code == clean[i].U_SBS_PRODUCTO);
          var variation = product.variations.find(function (variation) {
            return variation.U_SBS_MAQUINAS == clean[i].U_SBS_MAQUINAS && variation.U_SBS_VIGENCIA == clean[i].U_SBS_VIGENCIA
          });

          variation.min = clean[i];
        }

        // Asignamos las vigencias posibles a cada numero de dispositivos
        for (let i = 0; i < this.items.length; i++) {
          this.items[i].variations.sort((a, b) => a.U_SBS_MAQUINAS - b.U_SBS_MAQUINAS);
          this.items[i].equipos = this.items[i].variations[0].U_SBS_MAQUINAS;
          this.updateVigencia(this.items[i]);
        }
        
        this.products = clean;
        this.loading = false;
        this.activeProduct = this.items[0];
      }).catch(error => {
        this.loading = false;
      });
    },
    formatPrice(value) {
      let val = (value/1).toFixed(2).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    toKebabCase (str) {
      return str.replace(/([a-z])([A-Z0-9])/g, '$1-$2').toLowerCase()
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    add(product){
      product.quantity++
    },
    less(product){
      product.quantity--
    },
    async loadConfig() {
      this.loading = true;
      try {
        const response = await resourceConfig.list();
        if (response.status == 200) {
          const { data } = response.data;
          this.configs = data;
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    changeProductQuantity(product, type){
      const min = this.configs.filter(item => item.ItemCode === product.ItemCode).length > 0 ? 
        this.configs.filter(item => item.ItemCode === product.ItemCode)[0].min
        : 1;
      if(type === 'minus'){
        if(product.quantity > 0){
          const temp = product.quantity -1;
          if(temp > 0){
            if(temp >= min){
              product.quantity = temp;
            } else {
              product.quantity = 0;
            }
          } else {
            product.quantity = 0;
          }
        }
      } 
      if(type === 'plus'){
        const temp = product.quantity +1;
        if(temp > 0){
          if(temp < min){
            product.quantity = min;
          } else {
            product.quantity = temp;
          }
        } else {
          product.quantity = temp;
        }
      }
      if(type === 'change'){
        const temp = product.quantity;
        if(temp < min && temp !== 0){
          product.quantity = 0;
        }
      }
    },
    selectActiveProduct(event) {
      this.activeProduct = this.items.find((item) => item.code == event.target.value);
    },
    updateVigencia(product) {
      product.vigencia_options = product.variations.filter(variation => variation.U_SBS_MAQUINAS == product.equipos)
                                                .map(item => item.U_SBS_VIGENCIA);

      product.selected = product.variations.find(function(variation) {
        return  variation.U_SBS_MAQUINAS == product.equipos && variation.U_SBS_VIGENCIA == product.vigencia;
      });
    },
    addQuantity() {
      this.activeProduct.selected.quantity += 1;
      var temp = this.activeProduct.selected.quantity;
      if (typeof this.activeProduct.selected.min === 'object') {
        if (this.activeProduct.selected.quantity >= this.activeProduct.selected.min.min) {
          this.activeProduct.selected = this.activeProduct.selected.min;
          this.activeProduct.selected.quantity = temp;
        }
      }
    },
    subQuantity(variation) {
      if (variation.quantity > 1) {
        variation.quantity--;
        if (variation.min > 1) {
          if (variation.quantity < variation.min) {
            var product = this.items.find((item) => item.code == variation.U_SBS_PRODUCTO);
            var selected = product.variations.find(function (item) {
              return item.U_SBS_MAQUINAS == variation.U_SBS_MAQUINAS && item.U_SBS_VIGENCIA == variation.U_SBS_VIGENCIA
            });
            
            selected.quantity = variation.quantity;
            this.activeProduct.selected = selected;
          }
        }
      }
    },
    continueRouter(){
      this.$store.commit('add', this.activeProduct.selected);
      const inventory = this.$store.state.commerce.cart.filter(function(item){
        return item.ItemCode.includes('DST'); 
      });
      if(inventory.length > 0){
        this.$router.push('/commerceInventory/cart');
      }else{
        this.$router.push('/commerce/cart');
      }
    },
  }
}
</script>

<style scoped>
.cartAdd{
  font-size: large;
  border-radius: 50%;
  background: #00454e;
  border-color: #3c4b64;
}
.qtyInput{
  display: inline-flex;
  height: 35px!important;
  width: 47px;
}
.lessButton{
  border-radius: 50% 0px 0px 50%;
  height: 35px!important;
  background: #00454e;
  border-color: #00454e;
}
.addButton{
  border-radius: 0px 50% 50% 0px;
  height: 35px!important;
  background: #3c4b64;
  border-color: #3c4b64;
}

.form-control {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  margin: 0;
}

.qtyContainer{
  display: inline-flex;
  margin-right: 50px;
}

</style>